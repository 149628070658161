/**
 * @generated SignedSource<<96d8454ab9d791dde6b0a99591feff06>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DirectoryViewUiType = "list" | "timeline";
export type GoalSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "HIERARCHY_ASC" | "HIERARCHY_DESC" | "HIERARCHY_LEVEL_ASC" | "HIERARCHY_LEVEL_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "PHASE_ID_ASC" | "PHASE_ID_DESC" | "PROJECT_COUNT_ASC" | "PROJECT_COUNT_DESC" | "SCORE_ASC" | "SCORE_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type ProjectSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "STATUS_ASC" | "STATUS_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type StandardDirectoryViewColumnName = "CONTRIBUTORS" | "FOLLOWER_COUNT" | "FOLLOWING" | "GOALS" | "LAST_UPDATED" | "LINKS" | "OWNER" | "PROGRESS" | "RELATED_PROJECTS" | "START_DATE" | "STATUS" | "SUB_GOALS" | "TAGS" | "TARGET_DATE" | "TEAMS" | "WORK_TRACKING";
export type TimelineDateSpan = "month" | "week";
export type directoryViewColumnInput = {
  customFieldUuid?: string | null;
  standardFieldName?: StandardDirectoryViewColumnName | null;
};
export type UpdateDirectoryViewMutation$variables = {
  directoryViewColumns?: ReadonlyArray<directoryViewColumnInput | null> | null;
  directoryViewUuid: string;
  goalSort?: ReadonlyArray<GoalSortEnum | null> | null;
  name?: string | null;
  projectSort?: ReadonlyArray<ProjectSortEnum | null> | null;
  timelineDateSpan?: TimelineDateSpan | null;
  tql?: string | null;
  uiViewType?: DirectoryViewUiType | null;
  workspaceUuid: string;
};
export type UpdateDirectoryViewMutation$data = {
  readonly editDirectoryView: {
    readonly directoryView: {
      readonly " $fragmentSpreads": FragmentRefs<"storeDirectoryView">;
    } | null;
  } | null;
};
export type UpdateDirectoryViewMutation = {
  response: UpdateDirectoryViewMutation$data;
  variables: UpdateDirectoryViewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "directoryViewColumns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "directoryViewUuid"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "goalSort"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectSort"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timelineDateSpan"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tql"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uiViewType"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v9 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "directoryViewColumns",
        "variableName": "directoryViewColumns"
      },
      {
        "kind": "Variable",
        "name": "directoryViewUuid",
        "variableName": "directoryViewUuid"
      },
      {
        "kind": "Variable",
        "name": "goalSort",
        "variableName": "goalSort"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "projectSort",
        "variableName": "projectSort"
      },
      {
        "kind": "Variable",
        "name": "timelineDateSpan",
        "variableName": "timelineDateSpan"
      },
      {
        "kind": "Variable",
        "name": "tql",
        "variableName": "tql"
      },
      {
        "kind": "Variable",
        "name": "uiViewType",
        "variableName": "uiViewType"
      },
      {
        "kind": "Variable",
        "name": "workspaceUuid",
        "variableName": "workspaceUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v10 = {
  "kind": "ScalarField",
  "name": "id"
},
v11 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v12 = {
  "kind": "ScalarField",
  "name": "name"
},
v13 = {
  "kind": "ScalarField",
  "name": "storedTql"
},
v14 = {
  "kind": "ScalarField",
  "name": "entityType"
},
v15 = {
  "concreteType": "DirectoryViewSort",
  "kind": "LinkedField",
  "name": "sort",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "projectSort"
    },
    {
      "kind": "ScalarField",
      "name": "goalSort"
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "name": "uiViewType"
},
v17 = {
  "concreteType": "DirectoryViewUi",
  "kind": "LinkedField",
  "name": "uiMetadata",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "timelineDateSpan"
    }
  ]
},
v18 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v19 = {
  "kind": "ScalarField",
  "name": "favouriteCount"
},
v20 = {
  "kind": "ScalarField",
  "name": "aaid"
},
v21 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v22 = {
  "concreteType": "UserPII",
  "kind": "LinkedField",
  "name": "pii",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    {
      "kind": "ScalarField",
      "name": "picture"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "name": "UpdateDirectoryViewMutation",
    "selections": [
      {
        "args": (v9/*: any*/),
        "concreteType": "directoryViewPayload",
        "kind": "LinkedField",
        "name": "editDirectoryView",
        "plural": false,
        "selections": [
          {
            "concreteType": "DirectoryView",
            "kind": "LinkedField",
            "name": "directoryView",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "storeDirectoryView",
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "directoryViewColumns",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v21/*: any*/),
                          (v10/*: any*/),
                          (v12/*: any*/)
                        ],
                        "type": "StandardDirectoryViewColumn"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v21/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "type": "CustomDirectoryViewColumn"
                      }
                    ]
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "DirectoryFavoriteViewButton"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "DirectoryManageViewDialog"
                  }
                ],
                "argumentDefinitions": []
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v8/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateDirectoryViewMutation",
    "selections": [
      {
        "args": (v9/*: any*/),
        "concreteType": "directoryViewPayload",
        "kind": "LinkedField",
        "name": "editDirectoryView",
        "plural": false,
        "selections": [
          {
            "concreteType": "DirectoryView",
            "kind": "LinkedField",
            "name": "directoryView",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v10/*: any*/),
                  (v22/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "name": "directoryViewColumns",
                "plural": true,
                "selections": [
                  (v21/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v10/*: any*/),
                      (v12/*: any*/)
                    ],
                    "type": "StandardDirectoryViewColumn"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "type": "CustomDirectoryViewColumn"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              },
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "lastEditedBy",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v22/*: any*/),
                  (v10/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "name": "creationDate"
              },
              {
                "kind": "ScalarField",
                "name": "lastEditedDate"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "14435c0c9232aca930f3be4f0b669fa7",
    "id": null,
    "metadata": {},
    "name": "UpdateDirectoryViewMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDirectoryViewMutation(\n  $directoryViewUuid: UUID!\n  $workspaceUuid: UUID!\n  $name: String\n  $tql: String\n  $uiViewType: DirectoryViewUiType\n  $projectSort: [ProjectSortEnum]\n  $goalSort: [GoalSortEnum]\n  $timelineDateSpan: TimelineDateSpan\n  $directoryViewColumns: [directoryViewColumnInput]\n) {\n  editDirectoryView(input: {directoryViewUuid: $directoryViewUuid, workspaceUuid: $workspaceUuid, name: $name, tql: $tql, uiViewType: $uiViewType, projectSort: $projectSort, goalSort: $goalSort, timelineDateSpan: $timelineDateSpan, directoryViewColumns: $directoryViewColumns}) {\n    directoryView {\n      ...storeDirectoryView\n      id\n    }\n  }\n}\n\nfragment DeleteDirectoryViewModal on DirectoryView {\n  name\n  uuid\n  isFavourite\n  favouriteCount\n}\n\nfragment DirectoryFavoriteViewButton on DirectoryView {\n  id\n  uuid\n  name\n  isFavourite\n  entityType\n  ...DirectoryManageViewDialog\n}\n\nfragment DirectoryManageViewDialog on DirectoryView {\n  entityType\n  isFavourite\n  creator {\n    aaid\n    pii {\n      name\n      picture\n    }\n    id\n  }\n  lastEditedBy {\n    aaid\n    pii {\n      name\n      picture\n    }\n    id\n  }\n  creationDate\n  lastEditedDate\n  ...RenameDirectoryViewModal\n  ...DeleteDirectoryViewModal\n}\n\nfragment RenameDirectoryViewModal on DirectoryView {\n  name\n  uuid\n}\n\nfragment storeDirectoryView on DirectoryView {\n  id\n  uuid\n  name\n  storedTql\n  entityType\n  sort {\n    projectSort\n    goalSort\n  }\n  uiViewType\n  uiMetadata {\n    timelineDateSpan\n  }\n  isFavourite\n  favouriteCount\n  creator {\n    aaid\n    id\n  }\n  directoryViewColumns {\n    __typename\n    ... on StandardDirectoryViewColumn {\n      __typename\n      id\n      name\n    }\n    ... on CustomDirectoryViewColumn {\n      __typename\n      id\n      uuid\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  ...DirectoryFavoriteViewButton\n  ...DirectoryManageViewDialog\n}\n"
  }
};
})();

(node as any).hash = "36364b8ad9ce55e03658b7f8f4fb447e";

export default node;
