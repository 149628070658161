import { notFoundRoute } from '@townsquare/404-page-view/route';
import { chatRoute, createAgentRoute, editAgentRoute } from '@townsquare/chat-view/routes';
import { dashboardRoute, dashboardDirectoryRoute } from '@townsquare/dashboards-view/routes';
import {
  createKudosEmbedRoute,
  goalEmbedRoute,
  goalsDirectoryEmbedRoute,
  labelEmbedRoute,
  projectEmbedRoute,
  projectsDirectoryEmbedRoute,
  teamProfileEmbedRoute,
  userProfileEmbedRoute,
} from '@townsquare/embeds';
import { emptyOrNonProvisionedStatusUpdateStateRoute } from '@townsquare/empty-or-not-provisioned-state/route';
import { goalViewRoute } from '@townsquare/goal-view/route';
import { goalDirectoryRoute, goalNoWorkspaceDirectoryRoute } from '@townsquare/goals-directory-view/route';
import { homebarSideNavigationResource } from '@townsquare/home-layout/resources';
import {
  goalUpdateViewRoute,
  projectUpdateViewRoute,
  recentsViewRoute,
  savedViewsUpdatesRoute,
  tagFeedViewRoute,
  teamUpdatesViewRoute,
} from '@townsquare/home-view/routes';
import { appsRoute, homeTabRoute } from '@townsquare/hometab-view';
import { notificationsRoute } from '@townsquare/notifications-view/route';
import { projectRoute } from '@townsquare/project-view/route';
import { projectDirectoryRoute, projectNoWorkspaceDirectoryRoute } from '@townsquare/projects-directory-view/route';
import { kudosProfileRoute, profileRoute, teamProfileRoute } from '@townsquare/ptc-directory-view';
import { readingModeRoute } from '@townsquare/reading-mode-view/route';
import { applyResourcesToRoute, expandGlobalRoute } from '@townsquare/router/create-route';
import { HomepageRouteType, HomepageRoutes } from '@townsquare/router/route-types';
import { rovoEntitlementResource } from '@townsquare/rovo/entitlement';
import { rovoRoute } from '@townsquare/rovo-view';
import { searchConfigurationResource } from '@townsquare/search-view';
import { searchRoute } from '@townsquare/search-view/route';
import { settingsRoute } from '@townsquare/settings-view/route';
import { noWorkspaceStaffDirectoryRoute, staffDirectoryRoute } from '@townsquare/staff-directory-view/route';
import { tagsDirectoryRoute } from '@townsquare/tags-directory-view/route';
import {
  teamHealthMonitorEmbedRoute,
  teamHealthMonitorRoute,
  teamHealthRoute,
} from '@townsquare/team-health-view/routes';
import { topicRoute } from '@townsquare/topic-view/route';
import { followGoalActionRoute } from '@townsquare/url-actions/follow-goal/route';
import { followProjectActionRoute } from '@townsquare/url-actions/follow-project/route';
import { lookupRedirectActionRoute } from '@townsquare/url-actions/lookup-redirect/route';
import { reactionUpdateActionRoute } from '@townsquare/url-actions/reaction-update/route';
import { searchRedirectActionRoute } from '@townsquare/url-actions/search-redirect/route';

import { popupAuthRoute } from '../auth/popupLogin';

// When adding a new route, please also add it to the route-sanity-check E2E test
export const routeDefinitions: HomepageRoutes = [
  // Actions
  followProjectActionRoute,
  followGoalActionRoute,
  lookupRedirectActionRoute,
  reactionUpdateActionRoute,
  searchRedirectActionRoute,

  // Routes
  // staffDirectoryRoute needs to come before profileRoute
  staffDirectoryRoute,
  // profileRoute needs to come before teamProfileRoute and kuodsProfileRoute
  profileRoute,
  kudosProfileRoute,
  teamProfileRoute,
  createAgentRoute,
  editAgentRoute,
  chatRoute,
  goalDirectoryRoute,
  goalUpdateViewRoute,
  goalViewRoute,
  notificationsRoute,
  projectDirectoryRoute,
  projectRoute,
  projectUpdateViewRoute,
  readingModeRoute,
  savedViewsUpdatesRoute,
  searchRoute,
  rovoRoute,
  settingsRoute,
  tagFeedViewRoute,
  recentsViewRoute,
  teamUpdatesViewRoute,
  topicRoute,
  tagsDirectoryRoute,
  dashboardRoute,
  dashboardDirectoryRoute,
  teamHealthRoute,
  teamHealthMonitorRoute,
  appsRoute,

  // Embed routes
  createKudosEmbedRoute,
  goalEmbedRoute,
  goalsDirectoryEmbedRoute,
  labelEmbedRoute,
  projectEmbedRoute,
  projectsDirectoryEmbedRoute,
  teamProfileEmbedRoute,
  userProfileEmbedRoute,
  teamHealthMonitorEmbedRoute,

  // Fallback
  homeTabRoute,
  popupAuthRoute,
  notFoundRoute,
];

export const noWorkspaceRouteDefinitions = [
  goalNoWorkspaceDirectoryRoute,
  projectNoWorkspaceDirectoryRoute,
  notificationsRoute,
  emptyOrNonProvisionedStatusUpdateStateRoute,
  noWorkspaceStaffDirectoryRoute,
  createAgentRoute,
  editAgentRoute,
  chatRoute,
  searchRoute,
  rovoRoute,

  // Fallback
  homeTabRoute,
  notFoundRoute,
];

export const routes = routeDefinitions
  .flatMap<HomepageRouteType>(route => {
    if (route.routeType === 'global') {
      return expandGlobalRoute(route);
    }

    return route;
  })
  .map(route => {
    if (route.layout === 'home' && !route.embed) {
      route = applyResourcesToRoute(route, [homebarSideNavigationResource]);
    }

    if (route.routeType === 'global' || route.routeType === 'site') {
      route = applyResourcesToRoute(route, [rovoEntitlementResource, searchConfigurationResource]);
    }

    return route;
  });

export const noWorkspaceRoutes = noWorkspaceRouteDefinitions
  .flatMap<HomepageRouteType>(route => {
    if (route.routeType === 'global') {
      return expandGlobalRoute(route);
    }

    return route;
  })
  .map(route => {
    if (route.routeType === 'global' || route.routeType === 'site') {
      route = applyResourcesToRoute(route, [rovoEntitlementResource, searchConfigurationResource]);
    }

    return route;
  });
