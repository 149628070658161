// Integration FF's
export const FORGE_ENABLED_EXTENSION_POINTS = 'forge.enabled-extension-points';

// Other FF's not added/maintained by the Atlas dev team
//app.launchdarkly.com/projects/watermelon/flags/platform.design-system-team.enable-new-icons/targeting?env=production&env=staging&selected-env=staging
export const ENABLE_NEW_ICONS = 'platform.design-system-team.enable-new-icons';

// Flags owned by P&T

// open flag for allowing a user to override their locale
export const ATLAS_LOCALE_OVERRIDE = 'atlas.override-locale';

// variations/types for FF used in multiple packages
export const EPIC_IMPORT_PICKER = {
  VARIATION_A: 'variation-a',
  CONTROL_NOT_ENROLLED: 'control-not-enrolled',
};
export const EXPERIMENT_COHORTS = {
  VARIATION: 'variation',
  CONTROL: 'control',
  UNENROLLED: 'unenrolled',
};
