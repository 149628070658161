import Loadable from 'react-loadable';

import {
  CREATE_AGENT,
  CREATE_AGENT_PAGE,
  EDIT_AGENT,
  EDIT_AGENT_PAGE,
  FULL_PAGE_CHAT,
  FULL_PAGE_CHAT_PAGE,
} from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { rovoEntitlementResource } from '@townsquare/rovo/entitlement';

export const chatRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "chat-view" */ './components/ChatView/index'),
    loading: () => null,
  }),
  exact: true,
  name: FULL_PAGE_CHAT_PAGE,
  path: FULL_PAGE_CHAT,
  layout: 'home',
  nav4Layout: 'expanded',
  query: [
    'rovoChat!=false',
    'rovoChat.agentId!=false',
    'rovoChat.cloudId!=false',
    'rovoChat.conversationId!=false',
    'rovoChat.pathway!=false',
    'rovoChat.prompt!=false',
    'rovoChat!=false',
    'rovoChatAgentId!=false',
    'rovoChatCloudId!=false',
    'rovoChatConversationId!=false',
    'rovoChatPathway!=false',
    'rovoChatPrompt!=false',
    'rovoChatTriggerOpen!=false',
  ],
  resources: [rovoEntitlementResource],
});

export const createAgentRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "create-agent-view" */ './components/CreateAgentView/index'),
    loading: () => null,
  }),
  exact: true,
  name: CREATE_AGENT_PAGE,
  path: CREATE_AGENT,
  layout: 'home',
  nav4Layout: 'expanded',
  query: [
    'rovoChat!=false',
    'rovoChat.agentId!=false',
    'rovoChat.cloudId!=false',
    'rovoChat.conversationId!=false',
    'rovoChat.pathway!=false',
    'rovoChat.prompt!=false',
    'rovoChat!=false',
    'rovoChatAgentId!=false',
    'rovoChatCloudId!=false',
    'rovoChatConversationId!=false',
    'rovoChatPathway!=false',
    'rovoChatPrompt!=false',
    'rovoChatTriggerOpen!=false',
  ],
  resources: [rovoEntitlementResource],
});

export const editAgentRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "edit-agent-view" */ './components/EditAgentView/index'),
    loading: () => null,
  }),
  exact: true,
  name: EDIT_AGENT_PAGE,
  path: EDIT_AGENT,
  layout: 'home',
  nav4Layout: 'expanded',
  query: [
    'rovoChat!=false',
    'rovoChat.agentId!=false',
    'rovoChat.cloudId!=false',
    'rovoChat.conversationId!=false',
    'rovoChat.pathway!=false',
    'rovoChat.prompt!=false',
    'rovoChat!=false',
    'rovoChatAgentId!=false',
    'rovoChatCloudId!=false',
    'rovoChatConversationId!=false',
    'rovoChatPathway!=false',
    'rovoChatPrompt!=false',
    'rovoChatTriggerOpen!=false',
  ],
  resources: [rovoEntitlementResource],
});
