import { setGlobalTheme, useThemeObserver } from '@atlaskit/tokens';
import { useLayoutEffect } from 'react';

import { getConfig } from '@townsquare/config';

import { useThemeStore } from '../store';

function isLocationAtlas() {
  return window.top === null || window.top.location.origin === getConfig().fullUrl;
}

/**
 * This component conditionally requires global styles to turn on global theming
 * depending on the result of the ENABLE_GLOBAL_THEMING feature flag.
 *
 * - [Feature flag](https://app.launchdarkly.com/watermelon/staging/features/dst-global-theming)
 * - [#design-system-team-central](https://atlassian.slack.com/archives/C0256AVSPHN) to discuss
 */
export const GlobalTheming = () => {
  const [theme] = useThemeStore();
  const { colorMode: currentTheme } = useThemeObserver();

  useLayoutEffect(() => {
    // Enable global theming if we are in Atlas,
    // otherwise if we are outside of Atlas (e.g. embed, Jira Connect App...) we should not enable theming
    const setTheme = async () => {
      if (isLocationAtlas()) {
        await setGlobalTheme({ colorMode: theme, spacing: 'spacing', typography: 'typography-modernized' });
      }
    };

    setTheme().catch(_e => {
      /* Do nothing - i.e. don't set global theme */
    });
  }, [currentTheme, theme]);

  return null;
};
