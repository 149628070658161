import { createRelayResource } from '@townsquare/relay-utils';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import JiraIntegrationLinkQuery, {
  type JiraIntegrationLinkQuery as JiraIntegrationLinkQueryType,
} from '../components/ProjectSidebar/WorkTrackingLinks/JiraIntegrationLink/__generated__/JiraIntegrationLinkQuery.graphql';

export const jiraIntegrationLinkResource = createRelayResource<JiraIntegrationLinkQueryType>({
  getQuery: routerContext => {
    return {
      query: JiraIntegrationLinkQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        readFromAgsEnabled: isGateEnabled('read-from-ags-for-project-relationships'),
      },
    };
  },
});
