import FeatureGates from '@atlaskit/feature-gate-js-client';
import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';
import FeatureFlagClient, {
  AnalyticsClientInterface,
  AnonymousFlagUser,
  EnvironmentType,
  FeatureFlagClientInterface,
  FeatureFlagUser,
  FeatureFlagUserWithIdentifier,
  Identifiers,
} from '@atlassiansox/feature-flag-web-client';

import { getConfig } from '@townsquare/config';
import { ANALYTICS_PRODUCT_IDENTIFIER as FEATURE_FLAG_PRODUCT_IDENTIFIER } from '@townsquare/config/constants';
import { shouldEnablePlatformVisualRefresh } from '@townsquare/stat-sig/nav4';
import type { WorkspaceType } from '@townsquare/workspace-store/types';

import { ENABLE_NEW_ICONS } from './keys';

const config = getConfig();
export const isStaging = () => config.env === 'local' || config.env === 'stg-east';

const STAGING_API_KEY = 'd814e346-775a-4433-9cc2-bbf04f88c6a0'; // Watermelon LD project - Staging
const PROD_API_KEY = 'b4a2a380-5620-4085-8e89-80f9566c681c'; // Watermelon LD project - Production

export const featureFlagApiKey = isStaging() ? STAGING_API_KEY : PROD_API_KEY;
const featureFlagEnvironment = isStaging() ? EnvironmentType.STAGING : EnvironmentType.PROD;

export type { FeatureFlagClientInterface };

export let featureFlagClient: FeatureFlagClientInterface;

/**
 * Ensuring the featureFlagClient doesn't block critical path for long.
 *   - If there's a timeout, the frontend will fallback to defaults
 *   - If there's cached values, the client resolves instantly
 * In both cases the feature flags are still fetched and cached ready for the next refresh.
 **/
const READY_TIMEOUT_MS = 500;

const createFeatureFlagUser = (
  userAaId?: string,
  workspaceUuid = 'unable-to-resolve-workspace',
  cloudId?: string,
  additionalCustomContext: Record<string, string> = {},
): FeatureFlagUser => {
  const anonymousFlagUserFields: AnonymousFlagUser = {
    additionalIdentifiers: cloudId ? { [Identifiers.TENANT_ID]: cloudId } : undefined,
    custom: {
      workspaceUuid,
      ...additionalCustomContext,
    },
  };

  const userWithIdentifierFields: FeatureFlagUserWithIdentifier | Record<string, never> = userAaId
    ? {
        identifier: {
          type: Identifiers.ATLASSIAN_ACCOUNT_ID,
          value: userAaId,
        },
        isAnonymous: false,
      }
    : {};

  return {
    ...anonymousFlagUserFields,
    ...userWithIdentifierFields,
  };
};

export const initFeatureFlagClient = (options: {
  analyticsClient: AnalyticsClientInterface;
  userAaid?: string;
  workspaceUuid?: string;
  workspaceType?: WorkspaceType;
  cloudId?: string;
  additionalCustomContext?: Record<string, string>;
}) => {
  const { analyticsClient, userAaid, workspaceUuid, workspaceType, cloudId, additionalCustomContext } = options;
  const featureFlagUser = createFeatureFlagUser(userAaid, workspaceUuid, cloudId, additionalCustomContext);
  featureFlagClient = new FeatureFlagClient(featureFlagApiKey, analyticsClient, featureFlagUser, {
    productKey: FEATURE_FLAG_PRODUCT_IDENTIFIER,
    environment: featureFlagEnvironment,
    readyTimeoutInMilliseconds: READY_TIMEOUT_MS,
  });

  try {
    /**
     * Platform Feature flags (PFF): https://developer.atlassian.com/cloud/framework/atlassian-frontend/development/feature-flagging/platform-feature-flags/
     *
     * This creates a PFF resolver that allows calls to `getBooleanFF` inside of Platform components to use your feature flag client
     * and LD project, rather than installing a feature flag client of their own, or passing those flags in via props.
     */
    setBooleanFeatureFlagResolver(flagKey => {
      if (flagKey === 'platform-component-visual-refresh' || flagKey === ENABLE_NEW_ICONS) {
        // Require more granular checks to enable platform visual refresh
        return shouldEnablePlatformVisualRefresh(workspaceType);
      }
      if (FeatureGates.checkGate(flagKey)) {
        return true;
      }

      return Boolean(featureFlagClient.getFlagValue(flagKey, false));
    });
  } catch (error) {
    console.error('Could not initialize Platform Feature Flags', error);
  }

  return featureFlagClient;
};

export const setFeatureFlagClient = (client: FeatureFlagClientInterface) => {
  featureFlagClient = client;
  return featureFlagClient;
};
