import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import Popup from '@atlaskit/popup';
import { MenuListItem } from '@atlassian/navigation-system/v4';
import React, { useState } from 'react';
import { useIntl } from 'react-intl-next';
import { useQueryLoader } from 'react-relay';
import { usePathParam, useQueryParam, useResource } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { useOrgContextCloudIds, useOrgContextOrgId } from '@townsquare/org-context';
import { searchConfigurationResource } from '@townsquare/search-view';
import { useFeatureGate } from '@townsquare/stat-sig/gate';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { AccountPopup } from '../Profile';

import { DevWorkspaceSwitcherWrapper as DevWorkspaceSwitcher } from './DevWorkspaceSwitcher';
import { HomeWorkspaceSwitcherWrapper as HomeWorkspaceSwitcher } from './HomeWorkspaceSwitcher';
import { ProfileAndSwitchWorkspaceWrapper as ProfileAndSwitchWorkspace } from './ProfileAndWorkspaceSwitcher';
import DevWorkspaceSwitcherQuery, {
  DevWorkspaceSwitcherQuery as DevWorkspaceSwitcherQueryType,
} from './__generated__/DevWorkspaceSwitcherQuery.graphql';
import HomeWorkspaceSwitcherQuery, {
  HomeWorkspaceSwitcherQuery as HomeWorkspaceSwitcherQueryType,
} from './__generated__/HomeWorkspaceSwitcherQuery.graphql';
import ProfileAndWorkspaceSwitcherButtonQuery, {
  ProfileAndWorkspaceSwitcherButtonQuery as ProfileAndWorkspaceSwitcherButtonQueryType,
} from './__generated__/ProfileAndWorkspaceSwitcherButtonQuery.graphql';
import { WorkspaceButton } from './styles';

export const ProfileAndWorkspaceSwitcherButton = () => {
  const analytics = useAnalytics();
  const intl = useIntl();
  const [workspace] = useWorkspaceStore();
  const orgId = useOrgContextOrgId();
  const cloudIds = useOrgContextCloudIds();
  const [isOpen, setIsOpen] = useState(false);
  const [cloudId] = useQueryParam('cloudId');
  const [siteIdentifier] = usePathParam('siteIdentifier');
  const isDevModeEnabled = useFeatureGate('atlas_enable_split_brain_controls');
  const enableSiteSwitching = useFeatureGate('atlassian-home-site-switcher');
  const searchConfiguration = useResource(searchConfigurationResource);

  const onClose = () => {
    setIsOpen(false);
  };

  const [devQueryReference, loadDevQuery] = useQueryLoader<DevWorkspaceSwitcherQueryType>(DevWorkspaceSwitcherQuery);
  const [homeQueryReference, loadHomeQuery] =
    useQueryLoader<HomeWorkspaceSwitcherQueryType>(HomeWorkspaceSwitcherQuery);
  const [classicQueryReference, loadClassicQuery] = useQueryLoader<ProfileAndWorkspaceSwitcherButtonQueryType>(
    ProfileAndWorkspaceSwitcherButtonQuery,
  );

  const loadSwitcherQuery = () => {
    if (isDevModeEnabled) {
      loadDevQuery({});
    } else if (workspace.type !== 'PRODUCT') {
      loadHomeQuery({ context: { orgId, cloudId: cloudId ?? siteIdentifier } });
    } else {
      loadClassicQuery({});
    }
  };

  const onClick = () => {
    loadSwitcherQuery();
    void analytics.ui('workspaceSwitcherNavButton', 'clicked', {
      type: isDevModeEnabled ? 'dev' : workspace.type === 'GLOBAL_EXPERIENCE' ? 'home' : 'classic',
    });
    setIsOpen(!isOpen);
  };

  const onHover = () => {
    loadSwitcherQuery();
  };

  const siteName = searchConfiguration?.data?.siteMetadata.find(({ siteId }) => siteId === cloudId)?.siteName || '';

  const showSwitcher =
    isDevModeEnabled || (enableSiteSwitching ? cloudIds.length > 1 : workspace.numOfActiveWorkspaces > 1);

  return (
    // Hack to position workspace switcher on the far right side of the nav
    <>
      <AccountPopup />
      {showSwitcher && (
        <MenuListItem>
          <Popup
            placement="bottom-end"
            content={() =>
              isDevModeEnabled ? (
                <DevWorkspaceSwitcher queryReference={devQueryReference} />
              ) : workspace.type !== 'PRODUCT' ? (
                <HomeWorkspaceSwitcher queryReference={homeQueryReference} />
              ) : (
                <ProfileAndSwitchWorkspace queryReference={classicQueryReference} />
              )
            }
            isOpen={isOpen}
            onClose={onClose}
            trigger={({ ...triggerProps }) => (
              <EllipsisTooltip
                key={workspace.UUID}
                content={intl.formatMessage({
                  id: 'townsquare.navigation.profile-workspace-switcher.tooltip',
                  description: 'Tooltip for the switch workspace option',
                  defaultMessage: 'Switch workspace',
                })}
              >
                <WorkspaceButton onMouseOver={onHover}>
                  <PrimaryDropdownButton
                    {...triggerProps}
                    id="workspace-switcher-dropdown-button"
                    testId={`${workspace.type !== 'PRODUCT' ? 'home' : 'atlas'}-workspace-switcher-dropdown-button`}
                    onClick={onClick}
                  >
                    {enableSiteSwitching ? siteName : workspace.name}
                  </PrimaryDropdownButton>
                </WorkspaceButton>
              </EllipsisTooltip>
            )}
            testId="workspace-switcher-menu"
          />
        </MenuListItem>
      )}
    </>
  );
};
